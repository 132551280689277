import { isEmpty } from 'lodash';
import { ReactElement } from 'react';
import clsx from 'clsx';

import { BaseBlock } from '@/components/base-block';
import { BlockHeader } from '@/components/block-header';
import { CtaGridBlockType } from '@/lib/types';
import { getListItemsClassName } from '@/utils/get-list-items-class-name';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';
import { useRouter } from 'next/router';

const CtaGridBlock = ({
  title = '',
  layout = 'Default',
  width = 'Narrow',
  items = [],
  anchor,
}: CtaGridBlockType): ReactElement => {
  const isDefaultLayout = layout === 'Default';
  const isBlockLayout = layout === 'Block';

  const router = useRouter();

  return (
    <BaseBlock
      className={clsx('cta-grid-list cta-grid', {
        'with-background-colors': isBlockLayout,
      })}
      width={width}
      anchor={anchor}
    >
      <BlockHeader title={title} />
      {!isEmpty(items) && (
        <ul>
          {items.map((item, index) => {
            const hasIcon = Boolean(item.icon);
            const hasLink = Boolean(item.link);
            const hasDescription = Boolean(item.description);
            const isEven = (index + 1) % 2 === 0;
            const backgroundColor = (() => {
              if (HUNDRED_YEAR_TAKEOVER && router.route === '/rewrites/journeys') {
                return 'hundred-year-theme';
              }
              return isEven ? 'bg-ract-blue' : 'bg-active-blue';
            })();

            const itemDescription = (() => {
              if (!hasDescription) {
                return null;
              }
              if (isBlockLayout) {
                return <h5 className="text-white mb-3">{item.description}</h5>;
              }
              return <span className="cta-grid-text body-4">{item.description}</span>;
            })();

            return (
              <li key={item.id} className={getListItemsClassName(index, items.length)}>
                <div className={clsx('item-wrapper', isBlockLayout ? backgroundColor : '')}>
                  {isDefaultLayout && hasIcon && (
                    <Icon
                      name={item.icon}
                      className={clsx(
                        'cta-grid-icon',
                        HUNDRED_YEAR_TAKEOVER && router.route === '/' ? 'hundred-year-theme' : undefined
                      )}
                    />
                  )}
                  {itemDescription}
                  {hasLink && (
                    <Link
                      className={clsx(
                        'btn btn-xs',
                        isBlockLayout ? 'btn-outline-white' : 'btn-primary',
                        HUNDRED_YEAR_TAKEOVER && router.route === '/' ? 'hundred-year-theme solid' : undefined,
                        HUNDRED_YEAR_TAKEOVER && router.route === '/rewrites/journeys'
                          ? 'hundred-year-theme outline'
                          : undefined
                      )}
                      href={getModelURL(item.link)}
                      analytics={{ context: 'CTA - CTA Module', action: item.linkText }}
                    >
                      {item.linkText}
                    </Link>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </BaseBlock>
  );
};

export { CtaGridBlock };
